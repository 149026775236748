import Vue from 'vue'
import Router from 'vue-router'
import LoginPage from '@/pages/Login/LoginPage.vue'

//import MenuExterno from "@/components/MenuExterno";

import UnidadAcademicaPage from '@/pages/UnidadAcademica/UnidadAcademicaPage.vue'
import RolPage from '@/pages/Rol/RolPage.vue'
import PersonaPage from '@/pages/Persona/PersonaPage.vue'
import PrincipalPage from '@/pages/Principal/PrincipalPage.vue'
import CarreraPage from '@/pages/Carrera/CarreraPage.vue'
import ProfilePage from '@/pages/Profile/ProfilePage.vue'
import ReparticionPage from '@/pages/Reparticion/ReparticionPage.vue'
import TipoDocumentoPage from '@/pages/TipoDocumento/TipoDocumentoPage.vue'
import AreaInvestigacionPage from '@/pages/AreaInvestigacion/AreaInvestigacionPage.vue'
import DocumentoActivoPage from '@/pages/DocumentoActivo/DocumentoActivoPage.vue'
import RepositorioPage from '@/pages/Repositorio/RepositorioPage.vue'
import DocumentoPage from '@/pages/Documento/DocumentoPage.vue'
import ReportePage from '@/pages/Reporte/ReportePage.vue'

import EstadisticasPage from '@/pages/Estadisticas/EstadisticasPage.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Repositorio',
      component: RepositorioPage
    },
    {
      path: '/Principal',
      name: 'PrincipalPage',
      component: PrincipalPage
    },
    {
      path: '/UnidadAcademica',
      name: 'Unidad Academica',
      component: UnidadAcademicaPage
    },
    {
      path: '/Rol',
      name: 'Rol',
      component: RolPage
    },
    {
      path: '/Persona',
      name: 'Persona',
      component: PersonaPage
    },
    {
      path: '/Profile',
      name: 'Perfil',
      component: ProfilePage
    },
    {
      path: '/Login',
      name: 'Login',
      component: LoginPage
    }, 
    {
      path: '/Carrera',
      name: 'Carrera',
      component: CarreraPage
    },
    
    {
      path: '/Reparticion',
      name: 'Reparticion',
      component: ReparticionPage
    },
    {
      path: '/TipoDocumento',
      name: 'TipoDocumento',
      component: TipoDocumentoPage
    },
    {
      path: '/AreaInvestigacion',
      name: 'AreaInvestigacion',
      component: AreaInvestigacionPage
    },
    {
      path: '/Repositorio',
      name: 'Repositorio',
      component: RepositorioPage
    },
    {
      path: '/DocumentoActivo',
      name: 'DocumentoActivo',
      component: DocumentoActivoPage
    },
    {
      path: '/Documento',
      name: 'Documento',
      component: DocumentoPage
    },
    {
      path: '/Reporte',
      name: 'Reporte',
      component: ReportePage
    },
    {
      path: '/Estadisticas',
      name: 'Estadisticas',
      component: EstadisticasPage
    }

  ]
})