import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "DocumentoPage",

    
    data() {
        let ss = new MainService();
        return {
            //tab: null,
            //items: [
            //'primero', 'segundo'
            //],
            //text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',

            //activePicker: null,
            date: new Date().toISOString().substr(0, 10),
            picker: new Date().toISOString().substr(0, 10),
            menu: false,
            modal: false,
            menu2: false,
                    
            msg: "DocumentoPage",
            persona:{},
            ss: ss,
            ajax: {
                "url": ss.indexDocumento(),
                headers: ss.getToken(),
            },

               

          /*   watch: {
                menu (val) {
                val && setTimeout(() => (this.activePicker = 'YEAR'))
                },
            }, */
            
           
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Documento', name: 'd.Documento', title: 'Documento'},
                { data: 'ResponsableElaboracion', name: 'd.ResponsableElaboracion', title: 'Autor'},
                { data: 'Carrera', name: 'c.Carrera', title: 'Carrera'},
                { data: 'FechaElaboracion', name: 'd.FechaElaboracion', title: 'Fecha de Elaboracion'}, 
                { data: 'UnidadAcademica', name: 'ua.UnidadAcademica', title: 'Unidad Academica'},
                
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
           
            documentos: [],
            documento: {},
            
            tipoDocumento: {},
            tipoDocumentos:[],
            reparticiones:[],
            unidadAcademicas:[],
            masivo: {},

            carreras:[],
            carrera: {},

            documentoActivos:[],
            
            documentoActivo: {},

            areaInvestigaciones:[],

            rutaApp:process.env.VUE_APP_MAIN_SERVICE,

            ex7: "0",

            isLoading: false,
            isLoadingFile:false,
            errorBag: {},
            password: {}
        };
    },
    methods: {
       

        getDocumentoActivo() {
            this.ss.listDocumentoActivo().then(
                (result) => {
                    let response = result.data;
                    this.documentoActivos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },



        newDocumentoActivo() {
            this.documentoActivo = {};
            this.$refs['frm-documentoActivo'].show();
        },
        
        saveDocumentoActivo() {
            this.ss.storeDocumentoActivo(this.documentoActivo).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-documentoActivo'].hide();
                    this.$refs['datatable-documentoActivo'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });

                this.getDocumentoActivo()
        },

        cancelDocumentoActivo() {
            /* if (this.documentoActivo.id) {
                this.$refs['view-documentoActivo'].show();
            } */
            this.$refs['frm-documentoActivo'].hide();
        },

        /////////
        borrarSeleccionDocumentoActivo(){

            this.documento.documentoActivo = null; 

        },
        ///////////////////

        /* CARRERA SELECT */
        getCarrera() {
            this.ss.listCarrera().then(
                (result) => {
                    let response = result.data;
                    this.carreras = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        
        },

        newCarrera() {
            this.carrera = {};
            this.$refs['frm-carrera'].show();
        },
        
        saveCarrera() {
            this.ss.storeCarrera(this.carrera).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-carrera'].hide();
                    this.$refs['datatable-carrera'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });

                this.getCarrera()
        },

        cancelCarrera() {
            /* if (this.documentoActivo.id) {
                this.$refs['view-documentoActivo'].show();
            } */
            this.$refs['frm-carrera'].hide();
        },

        /////////
        borrarSeleccionCarrera(){

            this.documento.carrera = null; 

        },


        getTipoDocumento() {
            this.ss.listTipoDocumento().then(
                (result) => {
                    let response = result.data;
                    this.tipoDocumentos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        newTipoDocumento() {
            this.tipoDocumento = {};
            this.$refs['frm-tipoDocumento'].show();
        },
        saveTipoDocumento() {
            this.ss.storeTipoDocumento(this.tipoDocumento).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-tipoDocumento'].hide();
                    this.$refs['datatable-tipoDocumento'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });

                this.getTipoDocumento()
        },

        cancelTipoDocumento() {
            /* if (this.documentoActivo.id) {
                this.$refs['view-documentoActivo'].show();
            } */
            this.$refs['frm-tipoDocumento'].hide();
        },

        masivoArchivo()
        {
            window.open(this.rutaApp+ "storage/Files/cargado_masivo_example.xlsx");
        },

        masivoDocumento() {
            this.masivo = {};
            this.$refs['frm-masivoDocumento'].show();
        },
        cancelMasivo() {
            /* if (this.documentoActivo.id) {
                this.$refs['view-documentoActivo'].show();
            } */
            this.$refs['frm-masivoDocumento'].hide();
        },
 
        saveMasivo() {
            var data = new FormData();
            data.append('File', this.documento.File); 
            this.ss.storeDocumentoMasivo(data).then(
                (result) => {
                    console.log("resultadito",result);
                
                })
                .catch((error) => {
                    this.isLoadingFile = false;
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al subir el archivo',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                    console.log("res",error);
                });
        },

        loadArchivo(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.documento.File = input.files[0];
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.masivo.Archivo = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },

        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            );
                            this.documento.Imagen = result.data.data;

                            
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },


        loadFileA(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            );
                            this.documento.Archivo = result.data.data;
                            
                            
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },

        
       
        
        getReparticion() {
            this.ss.listReparticion().then(
                (result) => {
                    let response = result.data;
                    this.reparticiones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        
        
        deleteCodigoActivo () {
            this.getDocumentoActivo = false
        },
         
        

        getAreaInvestigacion() {
            this.ss.listAreaInvestigacion().then(
                (result) => {
                    let response = result.data;
                    this.areaInvestigaciones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        
        newDocumento() {
            this.documento = {};
            this.$refs['frm-documento'].show();
        },

        showDocumento(id) {
            console.log(id);
            this.isLoading=true;
            
            this.ss.showDocumento(id).then(
                (result) => {
                    let response = result.data;
                    this.documento = response.data;
                    this.$refs['view-documento'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editDocumento() {
            this.$refs['frm-documento'].show();
            this.$refs['view-documento'].hide();
        },
        cancelDocumento() {
            if (this.documento.id) {
                this.$refs['view-documento'].show();
            }
            this.$refs['frm-documento'].hide();
        },
        saveDocumento() {
            this.ss.storeDocumento(this.documento).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-documento'].hide();
                    this.$refs['datatable-documento'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteDocumento() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyDocumento(this.documento)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-documento'].hide();
                                this.$refs['datatable-documento'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-Documento').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showDocumento(data);
            });
        }
       
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        this.persona = JSON.parse(localStorage.getItem('persona'));

       /*  if (!persona) {
            this.$router.push('/Login');
        } else { */
            
            this.getTipoDocumento();
            this.getReparticion();
            this.getUnidadAcademica();
            this.getDocumentoActivo();
            this.getAreaInvestigacion();
            this.getCarrera();
            
       }
    
};
